.App {
    text-align: center;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

div {
    white-space: pre-wrap;
}

.drawer {
    height: 100%;
    overflow-y: auto;
    position: fixed;
}

::-webkit-scrollbar {
    display: none;
}

.bottomLink {
    text-decoration: none;
    color: #1b96c8;
    font-size: small
}